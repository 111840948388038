.icon {
	fill: currentColor;
	width: rem(32);
	height: rem(32);
	@include inline-block;
	vertical-align: middle;
	pointer-events: none; // You should never register clicks on icons, instead: use a button or <a>
}

.has-icon-left .icon {
	position: absolute;
	top: 0.65em;
	left: .75em;
	width: rem(24);
	height: rem(24);
}

.has-icon-right .icon {
	position: absolute;
	top: 0.65em;
	right: .75em;
	width: rem(24);
	height: rem(24);
}
