.select {
	position: relative;
	display: inline-block;
	width: 100%;
}

/**
 * Select icon
 */
.select:after {
	content: "";
	width: .5em;
	height: .5em;
	border-width: 0;
	border-right-width: 2px;
	border-bottom-width: 2px;
	border-color: c(border);
	border-style: solid;
	display: block;
	position: absolute;
	right: 1em;
	top: 50%;
	margin-top: -.35em;
	transform: rotate(45deg);
	pointer-events: none;
}

/**
 * Reset
 */
.select select {
	display: inline-block;
	width: 100%;
	cursor: pointer;
	border: 0;
	border-radius: 0;
	outline: 0;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

/**
 * Styling
 */
.select select {
	@include input-sizer();
	padding: $input-padding-vertical 2em $input-padding-vertical 1em;
	display: block;
	line-height: 1.15;
}

// .select select:focus {
// 	background: #fff !important;
// 	color: #000 !important;
// 	font-size: 16px !important;
// 	line-height: 1.6 !important;

// 	option {
// 		line-height: 1.8;
// 	}
// }

.select select::-ms-expand {
	display: none;
}