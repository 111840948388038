//
// Define some basics for the html and body element
// --------------------------------------------------

body, html {
	margin: 0 auto;
	width: 100%;
	background: #fff;
	font-size: $base-font-size;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	font-family: $font-stack;
	height: 100%;
}

.el {
	height: 2rem;
}

//
// Set the body to height: 100%
// --------------------------------------------------

body {
	height: 100%;
	display: flex;
	// min-height: 100vh;
	flex-direction: column;

	/**
	* Making the <body> element behave like a flexbox container:
	*/

	/* Ye Olde Flexbox for Webkit */
	display: -webkit-box;
	/* Tweener flexbox */
	display: -ms-flexbox;
	/* Prefixed "new flexbox" */
	display: -webkit-flex;
	/* unprefixed standard "new flexbox" version */
	display: flex;

	/**
	* Making the things inside <body> line up vertically
	*/

	/* Your grandparents flexbox for Webkit */
	-webkit-box-orient: vertical;
	/* Prefixed new syntax: */
	-webkit-flex-direction: column;
	/* Same syntax for weird IE tweener */
	-ms-flex-direction: column;
	/* unprefixed new syntax */
	flex-direction: column;

	&.twig-is-parsing {
		visibility: hidden;
	}
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

main.site-content {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	-webkit-flex: 1 0 auto;

	// flex-grow: 1;
	// flex-shrink: 1;
	// flex-basis: 0;


	display: flex;
	flex-direction: column;
	justify-content: stretch;

	max-height: 100%; // Old iOS
}

html a {
	color: currentColor;
	text-decoration: none;
}