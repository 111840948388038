.slideout-panel__close-trigger {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(255,255,255,0);
	display: none;
	z-index: 2;

	.slideout-open & {
		display: block;
	}
}

.slideout-menu {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 0;
	width: 256px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	display: none;
	background: c(primary);
}

.slideout-panel {
	position:relative;
	z-index: 1;
	background: #fff;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
	overflow-x: hidden;
}

.slideout-open .slideout-menu {
	display: block;
}
