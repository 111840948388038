// Neutral css
.question-overlay__inner {
	position: relative;
}

.question {
	font-size: 21px;
	line-height: 1.7;

	@include breakpoint(desktop-small) {
		font-size: 17px;
		line-height: 1.6;
	}

	@include breakpoint(tablet) {
		font-size: 17px;
	}

	@include breakpoint(mobile) {
		font-size: 17px;
	}
}

// if the question is in the header
.app-container.question-in-header .question-overlay br {
	display: none;
}

.app-container.question-in-header .question-overlay .question__button {
	color: c(primary);
	border-color: c(primary);
	@include viewport-type(24px, 18px);
}

// if the question fullscreen
.app-container.question-fullscreen .question-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: c(primary);
	z-index: 3;

	padding-top: 5em; // header height to center the rest

	@include breakpoint(mobile) {
		text-align: center;
		padding-top: 2em;
	}

	@include breakpoint(mini) {
		padding-top: 3em;
	}
}

.app-container.question-fullscreen .question-overlay .question {
	@include viewport-type(60px, 21px);
	color: c(contrast);
	font-weight: 600;
}

.app-container.question-fullscreen .question-overlay .question .question-layout {
	margin-bottom: 1em;

	@include breakpoint(mini) {
		margin-bottom: .5em;
	}
}

.app-container.question-fullscreen .question-overlay .question .question__button {
	@include viewport-type(36px, 21px);

	@include breakpoint(mobile) {
		padding: 0.7em 0.8em;
	}
}
.app-container.question-fullscreen .question-overlay .question .question__button.is-disabled {
	color: c(primary-dark);
	border-color: c(primary-dark);
	pointer-events: none;
}

.app-container.question-fullscreen .question-overlay .question .notification {
	@include viewport-type(30px, 16px);
	margin-top: 2em;

	@include breakpoint(mobile) {
		margin: 1em .5em 0;
	}
}

// Animation words
.question .dropdown .trigger1 {
	animation: colorChange .75s ease-in;
}

.trigger2 {
	animation: colorChange 1.5s ease-in;
}

.trigger3 {
	animation: colorChange 2.25s ease-in;
}

@keyframes colorChange {
	0% {
		color: c(contrast);
	}
	100% {
		color: c(primary-dark);
	}
}
