.content {
	@include vertical-rhythm();
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	margin: 0 0 .35em;
	line-height: 1.35;
}

p {
	font-size: 1.25em;
	line-height: 1.55;
	margin: 0 0 .5em;
}

ol {
	list-style: decimal outside;
	margin-left: 2em;
	margin-right: 2em;
	margin-top: 1em;
}

ul {
	list-style: disc outside;
}

ul li + li {
	margin-top: .75em;
}