.tile__bubble-holder .information-bubble {
	background-color: rgba(#000, .5);
	border-radius: 45px;
	padding: .5em 1.5em;
	flex: none;
	width: 100%;
	position: relative;
	color: c(contrast);
	text-align: center;
}

.tile__bubble-holder .information-bubble h3 {
	margin: 0;
	font-size: 1em;
}

.tile__bubble-holder .information-bubble p {
	margin: 0;
	font-size: 1.45em;

	@include breakpoint (small) {
		font-size: 1.2em;
	}

	@include breakpoint (mini) {
		font-size: 1em;
	}
}
