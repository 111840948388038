// Don't touch this unless you really know what you're doing :)
.input {
	@include input-sizer();
	padding: $input-padding-vertical 1em;
}

.input {
	display: inline-block;
	transition: $transition;
	outline: none;
	-webkit-appearance: none;
	width: 100%;
	line-height: 1;

	// Styling
	@include placeholder {
		color: lighten(#000, 60%);
		line-height: 1;
		vertical-align: middle;
		display: inline-block;
		display: block;
		line-height: 100%;
	}
}

.input:focus,
.select select:focus,
.button:focus {
	box-shadow: 0 0 15px 1px rgba(c(primary), .5);
}

//
// Style the input[type="radio"] & input[type="checkbox"]
// --------------------------------------------------

.input:disabled,
.input.is-disabled {
	background: #eee;
	color: #000;
	pointer-events: none;
}

//
// Resets
// --------------------------------------------------

input[type="text"]::ms-clear {
	display: none;
}

textarea {
	resize: vertical;
}
