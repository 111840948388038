html .is-small {
	font-size: .85em;
}

html .is-large {
	font-size: 1.25em;
}

html .is-extra-large {
	font-size: 1.75em;
}