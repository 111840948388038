.tiles {
	display: flex;
	justify-content: center;
	flex: none;
	width: 100%;

	@include breakpoint(between-small) {
		justify-content: flex-start;
	}
}

.tiles-holder {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 0;
	max-width: 100%;
	display: flex;
	position: relative;

	@include breakpoint(between-small) {
		width: auto;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		min-width: 0;
	}
}

.tile {
	position: relative;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 0;
	max-width: 100%;
	display: flex;
	align-items: flex-end;
	overflow: hidden;
	flex: none;
	width: (100% / 3);

	flex-direction: column;

	@include breakpoint(between-small) {
		flex: none;
		width: 55vw;
	}

	@include breakpoint(small) {
		width: 65vw;
	}

	@include breakpoint(mobile) {
		flex: none;
		width: 85vw;
	}
}

.tiles:not(.no-animate) .tile {
	transition: width 750ms ease-in-out;

	@include breakpoint(small) {
		transition: none;
	}
}

.tile__bg {
	transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform-origin: center center;
}

.tile__bubble-holder {
	padding: 2em 2vmin 1em;
	flex: none;
	width: 100%;

	@include breakpoint(tablet) {
		padding: 2em 1em 1em;
	}
}

.tile__bubble-holder .information-bubble .column {
	@include breakpoint(mini) {
		padding-left: 4px;
		padding-right: 4px;
	}
}

.tiles .tile + .tile {
	border-left: 2px solid c(contrast);
}

.tiles .tile + .tile.is-active {
	border-left: none;
}

.tile__header:before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 150%;
	background-image: linear-gradient(to bottom, rgba(#000, 0) 0%, rgba(#000, .4) 100%);
	z-index: -1;
	pointer-events: none;
}

.tile__header {
	position: relative;
	color: c(contrast);
	text-align: center;
	padding: 6.5vmin; // Get 6.5% of the smalles viewport axis
	z-index: 2;
	flex: none;
	width: 100%;
	flex-direction: column;
	display: flex;
	margin-top: auto; // Push the header to the bottom of the tile

	@include breakpoint(between-small) {
		padding: 7.5vmin;
	}

	@include breakpoint(mini) {
		padding: 12vmin;
	}
}

.tile__header h2 {
	line-height: 1.3;
	font-weight: normal;
	margin: 0 0 1em;
	text-align: left;
	display: block;
	overflow: hidden;

	flex: 1;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.tile__header h2 .fit {
	white-space: nowrap;
}

.tile__header h2 .fit.focus {
	font-family: $font-stack;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1;
}

.tile .is-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.tile__button {
	padding: .75em 1.5em;
	margin-top: auto;
	flex: none;
	background-color: rgba(#000, .15);
	font-weight: 600;
	@include viewport-type(21px, 18px);
}

.tile:hover:not(.is-active) > .tile__bg {
	transition: transform 1500ms ease-in-out;
	transform: scale(1.05);
}

// If tile is active (detailpage)
.tile.is-active {
	z-index: 2;
	// width: 100%;
	width: 100vw;
	// height: 100vh;
}

.tile.is-active .tile__header {
	display: none;
}

.tile.is-active .is-bg {
	transform: none;
}

.tile.is-active:hover .tile__bg {
	transform: none;
}

.tile.is-active > .tile__bg {
	filter: brightness(.5); // dimm the background
}

.tiles.tile-active .tile:not(.is-active) {
	width: 0 !important;
}

.tiles.tile-active .tile + .tile {
	border: none;
}

.tiles.tile-active .tile:not(.is-active) .tile__header,
.tiles.tile-active .tile:not(.is-active) .tile__bubble-holder {
	opacity: 0;
}

.tile.is-active .tile-detail-header__bubble {
	@include breakpoint(small) {
		display: none;
	}
}
