.dropdown {
	display: inline-block;
	position: relative;
}

.dropdown .icon {
	width: .65em;
	margin-left: .25em;
	margin-right: .35em;
}

.dropdown.is-active .dropdown__list {
	visibility: visible;
	opacity: 1;
}

.dropdown__list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	visibility: hidden;
	opacity: 0;
	transform: translateX(-50%);
	left: 50%;
	z-index: 5;
	text-align: left;

	max-height: 35vh;
	overflow: auto; // IE
	-webkit-overflow-scrolling: touch;
}

.dropdown__list li {
	background-color: c(primary-light);
	color: c(contrast);
	margin-top: -1px;
	padding-left: .5em;
	padding-right: .5em;
	white-space: nowrap;
}

.dropdown__list li:hover {
	color: c(primary-dark);
}

.dropdown__list li.checked {
	color: c(primary-dark);
}

.dropdown__trigger {
	color: c(primary);
}

// Dropdown for the fullscreen
.app-container.question-fullscreen .question-overlay .dropdown__trigger {
	color: c(primary-dark);
}

.app-container.question-fullscreen .question-overlay .dropdown__list li {
	font-size: 70%;

	@include breakpoint (tablet) {
		font-size: 90%;
	}

	@include breakpoint (small) {
		font-size: 100%;
	}
}

.app-container.question-fullscreen .dropdown.is-active .dropdown__scroll {
	position: absolute;
	width: 100%;
	text-align: center;
	background-image: linear-gradient(to bottom, rgba(#7fb6ff, 0) 0%, rgba(#7fb6ff, 1) 100%);
	animation: scrollDown 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	// animation-iteration-count: 5;
	bottom: 0;
	opacity: 0;
}

.app-container.question-fullscreen .header .dropdown__scroll .icon {
	fill: c(primary-dark);
	margin: 0;
}

@keyframes scrollDown {
	0%, 20%, 40%, 60% {
		bottom: -10px;
		opacity: 1;
	}

	10%, 30%, 50% {
		bottom: 0;
		opacity: 1;
	}

	80%, 90%, 100% {
		bottom: -10px;
		opacity: 0;
	}
}
