.radio {
	@include visually-hidden;
}

.radio:not(.is-custom) {
	& + label {
		position: relative;
		display: flex;
		margin-top: $input-padding-vertical; // fallback
		margin-top: calc($input-padding-vertical + 1px) ;// Border from input
		cursor: pointer;
	}

	& + label:before {
		flex: none;
		content: "";
		width: 1em;
		height: 1em;
		border: 1px solid c(border);
		margin-right: .5em;
		border-radius: 50%;
		background-color: c(contrast);
	}

	& + label .label {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 0;
		min-width: 0; // IE fix
		max-width: 100%; // IE fix
	}

	&:checked + label:before {
		// background: c(success);
		background-image: radial-gradient(ellipse at center, c(primary) 0%, c(primary) 38%, c(contrast) 39%);
	}
}

.radio.is-custom:not(:checked) + label.button.is-primary,
.radio.is-custom:not(:checked) + label > .button.is-primary {
	background-color: lighten(c(primary), 40%);
	border-color: lighten(c(primary), 20%);
	color: c(text-dark);
}

.radio.is-custom:not(:checked) + label > .button.is-primary {
	padding-left: (($input-padding-horizontal-icon + 1em) / 2);
	padding-right: (($input-padding-horizontal-icon + 1em) / 2);

	.icon {
		opacity: 0;
	}
}
