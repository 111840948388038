.styleguide-section {
	margin: 3em 0 7em;
	//@include viewport-type(20px, 16px);
}

.styleguide-section code {
	background-color: #efefef;
	display: inline-block;
	padding: .25em .5em;
	border-radius: 4px;
	border: 1px solid #ddd;
	font-size: .85em;
	line-height: 1;
}

.styleguide-logo {
	margin-left: auto;
	margin-right: auto;
	max-width: 200px;
}

.styleguide-section h2 {
	font-size: 2em;
	margin: 0 0 .25em;

	.styleguide-label {
		display: inline-block;
		background-color: #efefef;
		border-radius: 4px;
		padding: .2em .5em;
		border: 1px solid #ddd;
		font-size: .5em;
		vertical-align: middle;
	}
}

.styleguide-specs {
	width: 100%;
	margin-bottom: 2em;
	line-height: 1.6;

	td,
	th {
		border: 1px solid c(border);
		border-width: 0 0 1px;
		padding: .75em 1em;
		vertical-align: top;
	}

	th {
		color: c(text-dark);
		text-align: left;
	}

	// tr {
	// 	&:hover {
	// 		background-color: lighten(#000, 95%);
	// 	}
	// }

	thead {
		td,
		th {
			border-width: 0 0 2px;
			color: c(text-dark);
			font-weight: bold;
		}
	}

	tfoot {
		td,
		th {
			border-width: 2px 0 0;
			color: c(text-dark);
		}
	}

	tbody {
		tr {
			&:last-child {
				td,
				th {
					border-bottom-width: 0;
				}
			}
		}
	}
}