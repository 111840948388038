@mixin vertical-rhythm($spacing: 1.5em) {
	// First, reset all items
	& > * 		{ margin-top: 0; margin-bottom: 0;}

	// Next, allow margin-top to every item that follows a other item
	& > * + * 	{ margin-top: $spacing; }
}

@mixin input-sizer() {
	font-family: $font-stack;
	border-radius: $border-radius;
	border-width: 1px;
	border-style: solid;
	border-color: c(border);
	box-shadow: none;
	color: c(text-dark);
	background-color: c(contrast);
	line-height: 1;
	box-sizing: border-box;
}
