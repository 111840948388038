.control .control-icon {
	position: absolute;
	top: $input-padding-vertical; // Fallback
	top: calc(#{$input-padding-vertical} + 1px); // Accounting for the 1px border on inputs
	width: 1.25em;
	height: 1.25em;
}

.control .control-icon.right {
	right: .75em;
}

.control .control-icon.left {
	left: .75em;
}

.control > .control-icon.left ~ .input {
	padding-left: 2.25em;
}

.control > .control-icon.right ~ .input {
	padding-right: 2.25em;
}