.tile__detail-content {
	position: relative;
	color: c(contrast);
	z-index: 2;
	flex: none;
	width: 100%;
	padding-bottom: 5em;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	overflow: scroll; // doesn't work without height
	-webkit-overflow-scrolling: touch; // easy scrolling IOS
}

.tile__detail-content > .container {
	overflow: hidden;
}

.tile__detail-content h2 {
	font-weight: 600;
	font-size: 3.25em;

	@include breakpoint(tablet) {
		font-size: 2.2em;
	}

	@include breakpoint(small) {
		font-size: 1.75em;
	}

	@include breakpoint(mobile) {
		margin-bottom: .5em;
	}
}

.tile__detail-content h3 {
	font-weight: 600;
	font-size: 2.25em;

	@include breakpoint(tablet) {
		font-size: 1.4em;
	}

	@include breakpoint(mobile) {
		margin-bottom: .5em;
	}
}

.tile__detail-content p {
	font-size: 1.5em;

	@include breakpoint(tablet) {
		font-size: 1.25em;
	}
}

.tile__detail-content.intro {
	margin-top: auto; // Push the header to the bottom of the tile
	display: flex;
	align-items: flex-end;
	padding: 0 6em 5em; // fix for container

	@include breakpoint(desktop-small) {
		padding: 0 0 5em; // reset fix for container
	}

	@include breakpoint(small) {
		align-items: flex-start;
		padding: 2em 0;
	}
}

.tile__detail-content.activity-items {
	padding: 2em 6em 2em; // fix for container(6em)

	@include breakpoint(desktop-small) {
		padding: 2em 0 2em; // reset fix for container
	}
}

.activity-list {
	list-style: none;
	padding: 0;
	pointer-events: none;
}

.activity-list li {
	background-color: rgba(#000, .5);
}

.activity-list li .visual {
	display: flex;
	height: 15em;
	width: 26em;

	@include breakpoint(desktop-small) {
		width: 20em;
	}
}

.activity-list .media-text {
	padding: 1.5em 2em;

	@include breakpoint(mobile) {
		padding: 1.5em 1em;
	}
}

.activity-list .media-text p {
	@include breakpoint(mobile) {
		font-size: 1.1em;
	}
}
