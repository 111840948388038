.tile__detail-header {
	width: 100%;
	flex: none;
}

.tile.is-active .tile__detail-header {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	border-bottom: 2px solid c(contrast);
	color: c(contrast);

	padding: 1.5em 6em 0;

	@include breakpoint(desktop-small) {
		padding: 1.5em 0 0;
	}
}

.tile.is-active .tile__detail-header .column.tile-detail-header__bubble {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 0;
	max-width: 100%;
}

.tile.is-active .tile__detail-header .column.tile-detail-header__bubble {
	flex: none;
}

.tile.is-active .tile__bubble-holder {
	padding: 0;
}

.detail-overlay__nav {
	justify-content: space-between;
	list-style: none;
	display: flex;
	padding: 0;
	margin-bottom: 0;
}

.detail-overlay__nav a {
	display: block;
	border-bottom-width: 3px;
	border-bottom-style: solid;
	border-bottom-color: transparent;
	padding-bottom: .3em;
	opacity: .75;
}

.detail-overlay__nav li {
	font-size: 1.5em;

	@include breakpoint(mobile) {
		font-size: 1.25em;
	}
}

.detail-overlay__nav li.is-active a {
	border-bottom-color: c(contrast);
	opacity: 1;
	font-weight: 600;
}

.detail-overlay__nav li + li {
	margin-top: 0;
}

.tile__detail-header h1.city {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 3em;
	margin: 0;

	@include breakpoint(tablet) {
		font-size: 2.5em;
	}

	@include breakpoint(small) {
		font-size: 2em;
		padding-left: 1.5em;
	}

	@include breakpoint(mobile) {
		font-size: 1.5em;
	}
}

.tile__detail-header .close {
	position: absolute;
	left: -75px;
	top: 15px;

	@include breakpoint(tablet) {
		left: -47px;
		top: 10px;
	}

	@include breakpoint(small) {
		left: 10px;
		top: 5px;
	}
}

.tile__detail-header .close .icon {
	@include breakpoint(mobile) {
		width: 1.3em;
		height: 1.3em;
	}
}
