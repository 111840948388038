.is-media {
	display: flex;
	align-items: flex-start;
}

.is-media__img {
	margin-right: rem($base-spacing);
}

.is-media__body {
	flex: 1;
}

.is-media.is-y-center {
	align-items: center;
}

.is-media.is-y-bottom {
	align-items: flex-end;
}
