.field {
	position: relative;
}

.field .field-header .field-label {
	font-weight: 600;
	display: block;
}

.field:not(.is-horizontal) .field-header {
	margin-bottom: .5em;
}

.field .help {
	font-size: .85em;
	margin: .5em 0 0;
}

.field .help:empty,
.field.has-lazy-error:not(.has-success) .help {
	display: none;
}

.field-body {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 0; // IE fix
	max-width: 100%; // IE fix
}

$inputColors: ('error', 'info', 'warning', 'success');

.field.is-checking .input {
	box-shadow: 0 0 15px 1px rgba(c(warning), .5);
}

@each $color in $inputColors {

	@if($color == 'error') {
		.field.has-#{$color}:not(.has-lazy-error) {
			.input {
				border-color: c($color);
			}
	
			.input:focus {
				box-shadow: 0 0 15px 1px rgba(c($color), .5);
			}
	
			@if($color == 'error') {
				.help {
					color: c($color);
				}
			}
		}
	} @else {
		.field.has-#{$color} {
			.input {
				border-color: c($color);
			}
	
			.input:focus {
				box-shadow: 0 0 15px 1px rgba(c($color), .5);
			}
	
			@if($color == 'error') {
				.help {
					color: c($color);
				}
			}
		}
	}

	.field.has-#{$color}:not(.has-lazy-error) {
		.input {
			border-color: c($color);
		}

		.input:focus {
			box-shadow: 0 0 15px 1px rgba(c($color), .5);
		}

		@if($color == 'error') {
			.help {
				color: c($color);
			}
		}
	}
}

/*
 * Control (wrappers for fields / buttons)
 */

.field-body .control {
	position: relative;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 0; // IE fix
	max-width: 100%; // IE fix
}

.field-body .control.is-narrow {
	flex: none;
}

.field-body .control:not(:first-of-type):not(:last-of-type) > .button,
.field-body .control:not(:first-of-type):not(:last-of-type) > .input,
.field-body .control:not(:first-of-type):not(:last-of-type) > .select select {
	border-radius: 0;
}

.field-body .control:first-of-type > .button,
.field-body .control:first-of-type > .input,
.field-body .control:first-of-type > .select select {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.field-body .control + .control > .button,
.field-body .control + .control > .input,
.field-body .control + .control > .select select {
	border-left-width: 0;
}

.field-body .control:last-of-type > .button,
.field-body .control:last-of-type > .input,
.field-body .control:last-of-type > .select select {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

/*
 * Horizontal
 */
 .field.is-horizontal {
	display: flex;
}

.field.is-horizontal .field-header {
	flex: none;
	width: 20%; // Mag aangepast!
}

.field.is-horizontal .field-label {
	margin-top: $input-padding-vertical; // Fallback
	margin-top: calc(#{$input-padding-vertical} + 1px); // Accounting for the border on inputs
}