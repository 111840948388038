//
// Default font stack
// --------------------------------------------------

	$font-stack: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

	$font-heading: 'Volkhov', serif;

//
// Colors in the project.
// --------------------------------------------------

	$colors: (

		primary: 		#3366ff,
		primary-light:	#7fb6ff,
		primary-dark:	#003399,
		secondary: 		#CDDB49,
		alt: 			#FDC02F,

		light: 			#efefef,

		text-dark: 		#333,
		border:			#dcdcdc,

		contrast:		#fff,

		error:			#e74c3c,
		info: 			#3498db,
		success:		#2ecc71,
		warning:		#e67e22
	);

//
// Radius, spacing & transition.
// --------------------------------------------------

		$border-radius:					4px;
		$base-spacing:					16px;
		$transition:					all 0.2s ease-in-out;

//
// The vertical padding that will be applied to .input, .select select elements
// Also: the .control-icon and .field-label on horizontal fields will calculate
// their position using this value.
// --------------------------------------------------

		$input-padding-vertical: 		.8em;
		$input-padding-horizontal-icon: 2.65em;

//
// Base font size and line height.
// --------------------------------------------------

		$base-font-size:				16px;
		$base-line-height:				1.618;

//
// Content-spacing
// --------------------------------------------------

		$content-spacing: 				1em;
		$content-spacing-m: 			4em;
		$content-spacing-l: 			8em;

//
// Z-index values
// --------------------------------------------------

	$z-index: (
		cookie: 			20,
		full-link:			5,
		header:				20,
	);
