.header {
	background: c(contrast);
	width: 100%;
	z-index: z-index(header);
	padding: .75em 1.5em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: none;
}

.header .icon {
	fill: c(primary);
	z-index: z-index(header);
}

.header .header-left .icon {
	width: 8em;

	@include breakpoint(small) {
		width: 6em;
	}
}

.header .header-left {
	flex: none;
}

.header .header-left a {
	display: block;
}

.app-container.question-in-header .header .header-right {
	text-align: right;

	@include breakpoint(mobile) {
		text-align: center;
		margin-top: .5em;
	}
}

.app-container.question-fullscreen .header .icon {
	fill: c(contrast);
}

.app-container.question-fullscreen .header {
	padding: 1.5em;
}

.app-container.question-fullscreen .header .header-left a {
	display: flex;
}
