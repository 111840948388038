//
// Wrap inputs in this
// --------------------------------------------------

.form-element {
	position: relative;
	margin-bottom: 2em;
}

//
// Group inputs
//

.input-group {
	display: flex;
	margin: 0 0 2em;
	align-items: center;

	.is-expanded {
		flex-grow: 1;
		flex-shrink: 0;
	}

	> * {
		border-radius: 0;
	}

	> :first-child {
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}

	> :last-child {
		border-top-left-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
	}
}

.first-element {

	> :last-child {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}
}

.last-element {
	> :first-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
.input-notification:empty {
	display: none;
}

.input-notification {
	display: inline-block;
	padding: .2em .5em;
	border-radius: 5px;
	background-color: lighten(#000, 90%);
	color: #000;
	font-size: 1em;
	margin-top: 0.3em;
}

.input-notification.has-errors {
	background-color: c(error);
	color: c(contrast);
}

.input-notification.is-ok {
	background-color: c(success);
	color: c(contrast);
}

label.input-title {
    display: block;
	margin-top: calc(.5em + 1px);
}
