//
// Button resetter (for use with <button>)
// --------------------------------------------------

@mixin button-reset {
	border: 0;
	box-shadow: none;
	outline: none;
	-webkit-font-smoothing: antialiased;
	appearance: none;
	border-radius: none;
	border: none;
	color: white;
	cursor: pointer;
	display: inline-block;
	//line-height: 1;
	text-decoration: none;
	user-select: none;
	vertical-align: middle;
	border: 1px solid transparent;
	padding-left: 0;
	padding-right: 0;

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
}

//
// Button extender (for use with <button>)
// --------------------------------------------------

@mixin button {
	@include button-reset;
	@include inline-block;

	html body &:hover {
		text-decoration: none;
	}
}

.button-reset, .button {
	@include button;
}

.button {
	// border-radius: $border-radius * 1.5;
	// padding: $input-padding-vertical 1em;
	// line-height: 1.15;
	// border-width: 1px;
	// border-style: solid;
	// position: relative;

	border-radius: 4px;
	padding: $input-padding-vertical 1em;
	line-height: 1.15;
	border-width: 3px;
	border-style: solid;
	position: relative;
	color: c(contrast);
	border-color: c(contrast);
}

.button.has-icon-left {
	transition: $transition;
	padding-left: $input-padding-horizontal-icon;
}

.button.has-icon-right {
	transition: $transition;
	padding-right: $input-padding-horizontal-icon;
}

.button.is-primary {
	background-color: c(primary);
	border-color: c(primary);
}

.button.is-link {
	background-color: transparent;
	color: c(text-dark);
	padding-left: 0;
	padding-right: 0;
}

.buttons {
	display: flex;
}

.buttons > .button {
	margin-bottom: rem($grid-column-spacing);
}

.buttons > .button:not(:last-of-type) {
	margin-right: rem($grid-column-spacing);
}

.buttons.is-gapless > .button:not(:first-of-type):not(:last-of-type) {
	border-radius: 0;
}

.buttons.is-gapless > .button:first-of-type {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.buttons.is-gapless > .button:last-of-type {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.buttons.is-gapless > .button {
	margin-right: 0;
}
