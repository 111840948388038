@charset "UTF-8";
/*!
 * BRUUT™ Front-end Framework - Project Starter
 * @copyright: Onstuimig
 * @author: Onstuimig Interactieve Communicatie - onstuimig.nl
 * @preserve
 */
@import url(~normalize.css/normalize.css);
/*
* BRUUT™ Sass Package
* @copyright: Onstuimig
* @author: Onstuimig Interactieve Communicatie - onstuimig.nl
* @preserve
*/
@import url(~normalize.css/normalize.css);
@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster:
	                                   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
	     * Don't show links that are fragment identifiers,
	     * or use the `javascript:` pseudo protocol
	     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
	     * Printing Tables:
	     * http://css-discuss.incutio.com/wiki/Printing_Tables
	     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

html .is-flex {
  display: flex; }

html .is-x-left {
  justify-content: flex-start; }

html .is-x-center {
  justify-content: center; }

html .is-x-right {
  justify-content: flex-end; }

html .is-x-space-between {
  justify-content: space-between; }

html .is-x-space-around {
  justify-content: space-around; }

html .is-y-top {
  align-items: flex-start; }

html .is-y-center {
  align-items: center; }

html .is-y-bottom {
  align-items: flex-end; }

html .is-multiline {
  flex-wrap: wrap; }

.container {
  position: relative;
  margin: 0 auto;
  padding-right: 16px;
  padding-left: 16px;
  max-width: 1272px;
  width: 100%; }
  .container:before, .container:after {
    content: "";
    display: table; }
  .container:after {
    clear: both; }
  @media only screen and (max-width: 1200px) {
    .container {
      max-width: 832px; } }
  @media only screen and (max-width: 1024px) {
    .container {
      max-width: 632px; } }

.columns {
  display: flex;
  margin-right: -8px;
  margin-left: -8px; }
  .columns > .column {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 0; }

.columns.is-gapless {
  margin-left: 0;
  margin-right: 0; }
  .columns.is-gapless > .column {
    padding-left: 0;
    padding-right: 0; }

.columns.is-narrow {
  margin-left: -4px;
  margin-right: -4px; }
  .columns.is-narrow > .column {
    padding-left: 4px;
    padding-right: 4px; }

.columns > .column.is-narrow {
  flex: none; }

.columns > .column.is-full {
  flex: none;
  width: 100%; }

.columns > .column.is-three-quarters {
  flex: none;
  width: 75%; }

.columns > .column.is-two-thirds {
  flex: none;
  width: 66.6666667%; }

.columns > .column.is-half {
  flex: none;
  width: 50%; }

.columns > .column.is-one-third {
  flex: none;
  width: 33.3333332%; }

.columns > .column.is-one-quarter {
  flex: none;
  width: 25%; }

@media only screen and (max-width: 1200px) {
  .columns > .column.is-full-desktop-small {
    flex: none;
    width: 100%; }
  .columns > .column.is-three-quarters-desktop-small {
    flex: none;
    width: 75%; }
  .columns > .column.is-two-thirds-desktop-small {
    flex: none;
    width: 66.6666667%; }
  .columns > .column.is-half-desktop-small {
    flex: none;
    width: 50%; }
  .columns > .column.is-one-third-desktop-small {
    flex: none;
    width: 33.3333332%; }
  .columns > .column.is-one-quarter-desktop-small {
    flex: none;
    width: 25%; } }

@media only screen and (max-width: 1200px) {
  .columns > .column.is-narrow-desktop-small {
    flex: none; } }

@media only screen and (max-width: 1200px) {
  .columns > .column.is-auto-desktop-small {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    max-width: 100%; } }

@media only screen and (max-width: 1024px) {
  .columns > .column.is-full-tablet {
    flex: none;
    width: 100%; }
  .columns > .column.is-three-quarters-tablet {
    flex: none;
    width: 75%; }
  .columns > .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666667%; }
  .columns > .column.is-half-tablet {
    flex: none;
    width: 50%; }
  .columns > .column.is-one-third-tablet {
    flex: none;
    width: 33.3333332%; }
  .columns > .column.is-one-quarter-tablet {
    flex: none;
    width: 25%; } }

@media only screen and (max-width: 1024px) {
  .columns > .column.is-narrow-tablet {
    flex: none; } }

@media only screen and (max-width: 1024px) {
  .columns > .column.is-auto-tablet {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    max-width: 100%; } }

@media only screen and (max-width: 1023px) {
  .columns > .column.is-full-between-small {
    flex: none;
    width: 100%; }
  .columns > .column.is-three-quarters-between-small {
    flex: none;
    width: 75%; }
  .columns > .column.is-two-thirds-between-small {
    flex: none;
    width: 66.6666667%; }
  .columns > .column.is-half-between-small {
    flex: none;
    width: 50%; }
  .columns > .column.is-one-third-between-small {
    flex: none;
    width: 33.3333332%; }
  .columns > .column.is-one-quarter-between-small {
    flex: none;
    width: 25%; } }

@media only screen and (max-width: 1023px) {
  .columns > .column.is-narrow-between-small {
    flex: none; } }

@media only screen and (max-width: 1023px) {
  .columns > .column.is-auto-between-small {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    max-width: 100%; } }

@media only screen and (max-width: 767px) {
  .columns > .column.is-full-small {
    flex: none;
    width: 100%; }
  .columns > .column.is-three-quarters-small {
    flex: none;
    width: 75%; }
  .columns > .column.is-two-thirds-small {
    flex: none;
    width: 66.6666667%; }
  .columns > .column.is-half-small {
    flex: none;
    width: 50%; }
  .columns > .column.is-one-third-small {
    flex: none;
    width: 33.3333332%; }
  .columns > .column.is-one-quarter-small {
    flex: none;
    width: 25%; } }

@media only screen and (max-width: 767px) {
  .columns > .column.is-narrow-small {
    flex: none; } }

@media only screen and (max-width: 767px) {
  .columns > .column.is-auto-small {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    max-width: 100%; } }

@media only screen and (max-width: 480px) {
  .columns > .column.is-full-mobile {
    flex: none;
    width: 100%; }
  .columns > .column.is-three-quarters-mobile {
    flex: none;
    width: 75%; }
  .columns > .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666667%; }
  .columns > .column.is-half-mobile {
    flex: none;
    width: 50%; }
  .columns > .column.is-one-third-mobile {
    flex: none;
    width: 33.3333332%; }
  .columns > .column.is-one-quarter-mobile {
    flex: none;
    width: 25%; } }

@media only screen and (max-width: 480px) {
  .columns > .column.is-narrow-mobile {
    flex: none; } }

@media only screen and (max-width: 480px) {
  .columns > .column.is-auto-mobile {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    max-width: 100%; } }

@media only screen and (max-width: 320px) {
  .columns > .column.is-full-mini {
    flex: none;
    width: 100%; }
  .columns > .column.is-three-quarters-mini {
    flex: none;
    width: 75%; }
  .columns > .column.is-two-thirds-mini {
    flex: none;
    width: 66.6666667%; }
  .columns > .column.is-half-mini {
    flex: none;
    width: 50%; }
  .columns > .column.is-one-third-mini {
    flex: none;
    width: 33.3333332%; }
  .columns > .column.is-one-quarter-mini {
    flex: none;
    width: 25%; } }

@media only screen and (max-width: 320px) {
  .columns > .column.is-narrow-mini {
    flex: none; } }

@media only screen and (max-width: 320px) {
  .columns > .column.is-auto-mini {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    max-width: 100%; } }

html .is-color-primary {
  color: #3366ff; }

html .is-background-primary {
  background-color: #3366ff; }

html .is-border-primary {
  border-color: #3366ff; }

html .is-color-primary-light {
  color: #7fb6ff; }

html .is-background-primary-light {
  background-color: #7fb6ff; }

html .is-border-primary-light {
  border-color: #7fb6ff; }

html .is-color-primary-dark {
  color: #003399; }

html .is-background-primary-dark {
  background-color: #003399; }

html .is-border-primary-dark {
  border-color: #003399; }

html .is-color-secondary {
  color: #CDDB49; }

html .is-background-secondary {
  background-color: #CDDB49; }

html .is-border-secondary {
  border-color: #CDDB49; }

html .is-color-alt {
  color: #FDC02F; }

html .is-background-alt {
  background-color: #FDC02F; }

html .is-border-alt {
  border-color: #FDC02F; }

html .is-color-light {
  color: #efefef; }

html .is-background-light {
  background-color: #efefef; }

html .is-border-light {
  border-color: #efefef; }

html .is-color-text-dark {
  color: #333; }

html .is-background-text-dark {
  background-color: #333; }

html .is-border-text-dark {
  border-color: #333; }

html .is-color-border {
  color: #dcdcdc; }

html .is-background-border {
  background-color: #dcdcdc; }

html .is-border-border {
  border-color: #dcdcdc; }

html .is-color-contrast {
  color: #fff; }

html .is-background-contrast {
  background-color: #fff; }

html .is-border-contrast {
  border-color: #fff; }

html .is-color-error {
  color: #e74c3c; }

html .is-background-error {
  background-color: #e74c3c; }

html .is-border-error {
  border-color: #e74c3c; }

html .is-color-info {
  color: #3498db; }

html .is-background-info {
  background-color: #3498db; }

html .is-border-info {
  border-color: #3498db; }

html .is-color-success {
  color: #2ecc71; }

html .is-background-success {
  background-color: #2ecc71; }

html .is-border-success {
  border-color: #2ecc71; }

html .is-color-warning {
  color: #e67e22; }

html .is-background-warning {
  background-color: #e67e22; }

html .is-border-warning {
  border-color: #e67e22; }

.is-clearfix {
  display: block; }
  .is-clearfix:before, .is-clearfix:after {
    content: "";
    display: table; }
  .is-clearfix:after {
    clear: both; }

html .is-hidden {
  display: none; }

.is-full-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: transparent;
  display: block; }

html .is-visible-block,
html .is-visible {
  display: block; }

html .show-inline-block,
html .inline-block {
  display: inline-block; }

@media only screen and (max-width: 1200px) {
  html .is-hidden-desktop-small {
    display: none; } }

html .is-visible-desktop-small-block,
html .is-visible-desktop-small {
  display: none; }
  @media only screen and (max-width: 1200px) {
    html .is-visible-desktop-small-block,
    html .is-visible-desktop-small {
      display: block; } }

html .is-visible-desktop-small-inline-block {
  display: none; }
  @media only screen and (max-width: 1200px) {
    html .is-visible-desktop-small-inline-block {
      display: inline-block; } }

@media only screen and (max-width: 1024px) {
  html .is-hidden-tablet {
    display: none; } }

html .is-visible-tablet-block,
html .is-visible-tablet {
  display: none; }
  @media only screen and (max-width: 1024px) {
    html .is-visible-tablet-block,
    html .is-visible-tablet {
      display: block; } }

html .is-visible-tablet-inline-block {
  display: none; }
  @media only screen and (max-width: 1024px) {
    html .is-visible-tablet-inline-block {
      display: inline-block; } }

@media only screen and (max-width: 1023px) {
  html .is-hidden-between-small {
    display: none; } }

html .is-visible-between-small-block,
html .is-visible-between-small {
  display: none; }
  @media only screen and (max-width: 1023px) {
    html .is-visible-between-small-block,
    html .is-visible-between-small {
      display: block; } }

html .is-visible-between-small-inline-block {
  display: none; }
  @media only screen and (max-width: 1023px) {
    html .is-visible-between-small-inline-block {
      display: inline-block; } }

@media only screen and (max-width: 767px) {
  html .is-hidden-small {
    display: none; } }

html .is-visible-small-block,
html .is-visible-small {
  display: none; }
  @media only screen and (max-width: 767px) {
    html .is-visible-small-block,
    html .is-visible-small {
      display: block; } }

html .is-visible-small-inline-block {
  display: none; }
  @media only screen and (max-width: 767px) {
    html .is-visible-small-inline-block {
      display: inline-block; } }

@media only screen and (max-width: 480px) {
  html .is-hidden-mobile {
    display: none; } }

html .is-visible-mobile-block,
html .is-visible-mobile {
  display: none; }
  @media only screen and (max-width: 480px) {
    html .is-visible-mobile-block,
    html .is-visible-mobile {
      display: block; } }

html .is-visible-mobile-inline-block {
  display: none; }
  @media only screen and (max-width: 480px) {
    html .is-visible-mobile-inline-block {
      display: inline-block; } }

@media only screen and (max-width: 320px) {
  html .is-hidden-mini {
    display: none; } }

html .is-visible-mini-block,
html .is-visible-mini {
  display: none; }
  @media only screen and (max-width: 320px) {
    html .is-visible-mini-block,
    html .is-visible-mini {
      display: block; } }

html .is-visible-mini-inline-block {
  display: none; }
  @media only screen and (max-width: 320px) {
    html .is-visible-mini-inline-block {
      display: inline-block; } }

.is-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.is-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

html .has-vertical-rhythm > * {
  margin-top: 0;
  margin-bottom: 0; }

html .has-vertical-rhythm > * + * {
  margin-top: 1.5em; }

html.ie8 .is-visually-hidden {
  display: none !important;
  *display: none !important; }

.icon {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none; }

.has-icon-left .icon {
  position: absolute;
  top: 0.65em;
  left: .75em;
  width: 1.5rem;
  height: 1.5rem; }

.has-icon-right .icon {
  position: absolute;
  top: 0.65em;
  right: .75em;
  width: 1.5rem;
  height: 1.5rem; }

.ratio-16x9:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 56.25%; }

.ratio-16x9:after {
  content: '';
  display: table;
  clear: both; }

.cover-image[data-bg-position="left bottom"] {
  background-position: left bottom; }

.cover-image[data-bg-position="center bottom"] {
  background-position: center bottom; }

.cover-image[data-bg-position="right bottom"] {
  background-position: right bottom; }

.cover-image[data-bg-position="left center"] {
  background-position: left center; }

.cover-image[data-bg-position="center center"] {
  background-position: center center; }

.cover-image[data-bg-position="right center"] {
  background-position: right center; }

.cover-image[data-bg-position="left top"] {
  background-position: left top; }

.cover-image[data-bg-position="center top"] {
  background-position: center top; }

.cover-image[data-bg-position="right top"] {
  background-position: right top; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

[class*="ratio"] {
  position: relative; }

[class*="ratio-"] > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

img.is-lazy,
img.is-loading,
.is-bg.is-lazy
.is-bg.is-loading {
  opacity: 0; }

.is-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative; }

img.is-loaded,
.is-bg.is-loaded {
  animation: fadeIn .5s both; }

.is-responsive-embed {
  position: relative; }
  .is-responsive-embed .responsive-embed__item,
  .is-responsive-embed iframe,
  .is-responsive-embed embed,
  .is-responsive-embed object,
  .is-responsive-embed video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.input {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #dcdcdc;
  box-shadow: none;
  color: #333;
  background-color: #fff;
  line-height: 1;
  box-sizing: border-box;
  padding: 0.8em 1em; }

.input {
  display: inline-block;
  transition: all 0.2s ease-in-out;
  outline: none;
  -webkit-appearance: none;
  width: 100%;
  line-height: 1; }
  .input::-webkit-input-placeholder {
    color: #999999;
    line-height: 1;
    vertical-align: middle;
    display: inline-block;
    display: block;
    line-height: 100%; }
  .input::-moz-placeholder {
    color: #999999;
    line-height: 1;
    vertical-align: middle;
    display: inline-block;
    display: block;
    line-height: 100%; }
  .input:-moz-placeholder {
    color: #999999;
    line-height: 1;
    vertical-align: middle;
    display: inline-block;
    display: block;
    line-height: 100%; }
  .input:-ms-input-placeholder {
    color: #999999;
    line-height: 1;
    vertical-align: middle;
    display: inline-block;
    display: block;
    line-height: 100%; }

.input:focus,
.select select:focus,
.button:focus {
  box-shadow: 0 0 15px 1px rgba(51, 102, 255, 0.5); }

.input:disabled,
.input.is-disabled {
  background: #eee;
  color: #000;
  pointer-events: none; }

input[type="text"]::ms-clear {
  display: none; }

textarea {
  resize: vertical; }

.checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.checkbox:not(.is-custom) + label {
  position: relative;
  display: inline-block;
  line-height: 1;
  width: auto;
  margin-bottom: 1rem;
  padding-right: 1rem;
  display: flex;
  cursor: pointer; }

.checkbox:not(.is-custom) + label:before {
  flex: none;
  content: "";
  width: 1em;
  height: 1em;
  border: 1px solid #dcdcdc;
  margin-right: .5em; }

.checkbox:not(.is-custom) + label .label {
  flex: 1; }

.checkbox:not(.is-custom):checked + label > .icon {
  position: absolute;
  display: inline-block;
  top: -.1em;
  left: 0;
  width: 1.125rem;
  height: 1.125rem; }

.checkbox:not(.is-custom) + label > .icon {
  display: none; }

.checkbox.is-custom + label > .button.is-primary {
  position: relative;
  opacity: .3; }

.checkbox.is-custom:checked + label > .button.is-primary {
  opacity: 1; }

.checkbox.is-custom + label > .button.is-primary.has-icon-left {
  padding-left: 2.65em; }

.checkbox.is-custom + label > .button.is-primary.has-icon-right {
  padding-right: 2.65em; }

.radio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.radio:not(.is-custom) + label {
  position: relative;
  display: flex;
  margin-top: 0.8em;
  margin-top: calc($input-padding-vertical + 1px);
  cursor: pointer; }

.radio:not(.is-custom) + label:before {
  flex: none;
  content: "";
  width: 1em;
  height: 1em;
  border: 1px solid #dcdcdc;
  margin-right: .5em;
  border-radius: 50%;
  background-color: #fff; }

.radio:not(.is-custom) + label .label {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 0;
  max-width: 100%; }

.radio:not(.is-custom):checked + label:before {
  background-image: radial-gradient(ellipse at center, #3366ff 0%, #3366ff 38%, #fff 39%); }

.radio.is-custom:not(:checked) + label.button.is-primary,
.radio.is-custom:not(:checked) + label > .button.is-primary {
  background-color: white;
  border-color: #99b3ff;
  color: #333; }

.radio.is-custom:not(:checked) + label > .button.is-primary {
  padding-left: 1.825em;
  padding-right: 1.825em; }
  .radio.is-custom:not(:checked) + label > .button.is-primary .icon {
    opacity: 0; }

.select {
  position: relative;
  display: inline-block;
  width: 100%; }

/**
 * Select icon
 */
.select:after {
  content: "";
  width: .5em;
  height: .5em;
  border-width: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-color: #dcdcdc;
  border-style: solid;
  display: block;
  position: absolute;
  right: 1em;
  top: 50%;
  margin-top: -.35em;
  transform: rotate(45deg);
  pointer-events: none; }

/**
 * Reset
 */
.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; }

/**
 * Styling
 */
.select select {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #dcdcdc;
  box-shadow: none;
  color: #333;
  background-color: #fff;
  line-height: 1;
  box-sizing: border-box;
  padding: 0.8em 2em 0.8em 1em;
  display: block;
  line-height: 1.15; }

.select select::-ms-expand {
  display: none; }

.form-element {
  position: relative;
  margin-bottom: 2em; }

.input-group {
  display: flex;
  margin: 0 0 2em;
  align-items: center; }
  .input-group .is-expanded {
    flex-grow: 1;
    flex-shrink: 0; }
  .input-group > * {
    border-radius: 0; }
  .input-group > :first-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .input-group > :last-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }

.first-element > :last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0; }

.last-element > :first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-notification:empty {
  display: none; }

.input-notification {
  display: inline-block;
  padding: .2em .5em;
  border-radius: 5px;
  background-color: #e6e6e6;
  color: #000;
  font-size: 1em;
  margin-top: 0.3em; }

.input-notification.has-errors {
  background-color: #e74c3c;
  color: #fff; }

.input-notification.is-ok {
  background-color: #2ecc71;
  color: #fff; }

label.input-title {
  display: block;
  margin-top: calc(.5em + 1px); }

.button-reset, .button {
  border: 0;
  box-shadow: none;
  outline: none;
  -webkit-font-smoothing: antialiased;
  appearance: none;
  border-radius: none;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  border: 1px solid transparent;
  padding-left: 0;
  padding-right: 0;
  display: inline-block; }
  .button-reset::-moz-focus-inner, .button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  html body .button-reset:hover, html body .button:hover {
    text-decoration: none; }

.button {
  border-radius: 4px;
  padding: 0.8em 1em;
  line-height: 1.15;
  border-width: 3px;
  border-style: solid;
  position: relative;
  color: #fff;
  border-color: #fff; }

.button.has-icon-left {
  transition: all 0.2s ease-in-out;
  padding-left: 2.65em; }

.button.has-icon-right {
  transition: all 0.2s ease-in-out;
  padding-right: 2.65em; }

.button.is-primary {
  background-color: #3366ff;
  border-color: #3366ff; }

.button.is-link {
  background-color: transparent;
  color: #333;
  padding-left: 0;
  padding-right: 0; }

.buttons {
  display: flex; }

.buttons > .button {
  margin-bottom: 0.5rem; }

.buttons > .button:not(:last-of-type) {
  margin-right: 0.5rem; }

.buttons.is-gapless > .button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0; }

.buttons.is-gapless > .button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.buttons.is-gapless > .button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.buttons.is-gapless > .button {
  margin-right: 0; }

.is-media {
  display: flex;
  align-items: flex-start; }

.is-media__img {
  margin-right: 1rem; }

.is-media__body {
  flex: 1; }

.is-media.is-y-center {
  align-items: center; }

.is-media.is-y-bottom {
  align-items: flex-end; }

body, html {
  margin: 0 auto;
  width: 100%;
  background: #fff;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  height: 100%; }

.el {
  height: 2rem; }

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  /**
	* Making the <body> element behave like a flexbox container:
	*/
  /* Ye Olde Flexbox for Webkit */
  display: -webkit-box;
  /* Tweener flexbox */
  display: -ms-flexbox;
  /* Prefixed "new flexbox" */
  display: -webkit-flex;
  /* unprefixed standard "new flexbox" version */
  display: flex;
  /**
	* Making the things inside <body> line up vertically
	*/
  /* Your grandparents flexbox for Webkit */
  -webkit-box-orient: vertical;
  /* Prefixed new syntax: */
  -webkit-flex-direction: column;
  /* Same syntax for weird IE tweener */
  -ms-flex-direction: column;
  /* unprefixed new syntax */
  flex-direction: column; }
  body.twig-is-parsing {
    visibility: hidden; }

*,
*::before,
*::after {
  box-sizing: inherit; }

main.site-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-height: 100%; }

html a {
  color: currentColor;
  text-decoration: none; }

.box {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-bottom: 1.5em; }

.box[class*="is-background-"] {
  padding-left: 1.5em;
  padding-right: 1.5em; }

.field {
  position: relative; }

.field .field-header .field-label {
  font-weight: 600;
  display: block; }

.field:not(.is-horizontal) .field-header {
  margin-bottom: .5em; }

.field .help {
  font-size: .85em;
  margin: .5em 0 0; }

.field .help:empty,
.field.has-lazy-error:not(.has-success) .help {
  display: none; }

.field-body {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 0;
  max-width: 100%; }

.field.is-checking .input {
  box-shadow: 0 0 15px 1px rgba(230, 126, 34, 0.5); }

.field.has-error:not(.has-lazy-error) .input {
  border-color: #e74c3c; }

.field.has-error:not(.has-lazy-error) .input:focus {
  box-shadow: 0 0 15px 1px rgba(231, 76, 60, 0.5); }

.field.has-error:not(.has-lazy-error) .help {
  color: #e74c3c; }

.field.has-error:not(.has-lazy-error) .input {
  border-color: #e74c3c; }

.field.has-error:not(.has-lazy-error) .input:focus {
  box-shadow: 0 0 15px 1px rgba(231, 76, 60, 0.5); }

.field.has-error:not(.has-lazy-error) .help {
  color: #e74c3c; }

.field.has-info .input {
  border-color: #3498db; }

.field.has-info .input:focus {
  box-shadow: 0 0 15px 1px rgba(52, 152, 219, 0.5); }

.field.has-info:not(.has-lazy-error) .input {
  border-color: #3498db; }

.field.has-info:not(.has-lazy-error) .input:focus {
  box-shadow: 0 0 15px 1px rgba(52, 152, 219, 0.5); }

.field.has-warning .input {
  border-color: #e67e22; }

.field.has-warning .input:focus {
  box-shadow: 0 0 15px 1px rgba(230, 126, 34, 0.5); }

.field.has-warning:not(.has-lazy-error) .input {
  border-color: #e67e22; }

.field.has-warning:not(.has-lazy-error) .input:focus {
  box-shadow: 0 0 15px 1px rgba(230, 126, 34, 0.5); }

.field.has-success .input {
  border-color: #2ecc71; }

.field.has-success .input:focus {
  box-shadow: 0 0 15px 1px rgba(46, 204, 113, 0.5); }

.field.has-success:not(.has-lazy-error) .input {
  border-color: #2ecc71; }

.field.has-success:not(.has-lazy-error) .input:focus {
  box-shadow: 0 0 15px 1px rgba(46, 204, 113, 0.5); }

/*
 * Control (wrappers for fields / buttons)
 */
.field-body .control {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 0;
  max-width: 100%; }

.field-body .control.is-narrow {
  flex: none; }

.field-body .control:not(:first-of-type):not(:last-of-type) > .button,
.field-body .control:not(:first-of-type):not(:last-of-type) > .input,
.field-body .control:not(:first-of-type):not(:last-of-type) > .select select {
  border-radius: 0; }

.field-body .control:first-of-type > .button,
.field-body .control:first-of-type > .input,
.field-body .control:first-of-type > .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.field-body .control + .control > .button,
.field-body .control + .control > .input,
.field-body .control + .control > .select select {
  border-left-width: 0; }

.field-body .control:last-of-type > .button,
.field-body .control:last-of-type > .input,
.field-body .control:last-of-type > .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/*
 * Horizontal
 */
.field.is-horizontal {
  display: flex; }

.field.is-horizontal .field-header {
  flex: none;
  width: 20%; }

.field.is-horizontal .field-label {
  margin-top: 0.8em;
  margin-top: calc(0.8em + 1px); }

.control .control-icon {
  position: absolute;
  top: 0.8em;
  top: calc(0.8em + 1px);
  width: 1.25em;
  height: 1.25em; }

.control .control-icon.right {
  right: .75em; }

.control .control-icon.left {
  left: .75em; }

.control > .control-icon.left ~ .input {
  padding-left: 2.25em; }

.control > .control-icon.right ~ .input {
  padding-right: 2.25em; }

html .is-small {
  font-size: .85em; }

html .is-large {
  font-size: 1.25em; }

html .is-extra-large {
  font-size: 1.75em; }

.content > * {
  margin-top: 0;
  margin-bottom: 0; }

.content > * + * {
  margin-top: 1.5em; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0 0 .35em;
  line-height: 1.35; }

p {
  font-size: 1.25em;
  line-height: 1.55;
  margin: 0 0 .5em; }

ol {
  list-style: decimal outside;
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 1em; }

ul {
  list-style: disc outside; }

ul li + li {
  margin-top: .75em; }

.tiles {
  display: flex;
  justify-content: center;
  flex: none;
  width: 100%; }
  @media only screen and (max-width: 1023px) {
    .tiles {
      justify-content: flex-start; } }

.tiles-holder {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 0;
  max-width: 100%;
  display: flex;
  position: relative; }
  @media only screen and (max-width: 1023px) {
    .tiles-holder {
      width: auto;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      min-width: 0; } }

.tile {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 0;
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  flex: none;
  width: 33.33333%;
  flex-direction: column; }
  @media only screen and (max-width: 1023px) {
    .tile {
      flex: none;
      width: 55vw; } }
  @media only screen and (max-width: 767px) {
    .tile {
      width: 65vw; } }
  @media only screen and (max-width: 480px) {
    .tile {
      flex: none;
      width: 85vw; } }

.tiles:not(.no-animate) .tile {
  transition: width 750ms ease-in-out; }
  @media only screen and (max-width: 767px) {
    .tiles:not(.no-animate) .tile {
      transition: none; } }

.tile__bg {
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: center center; }

.tile__bubble-holder {
  padding: 2em 2vmin 1em;
  flex: none;
  width: 100%; }
  @media only screen and (max-width: 1024px) {
    .tile__bubble-holder {
      padding: 2em 1em 1em; } }

@media only screen and (max-width: 320px) {
  .tile__bubble-holder .information-bubble .column {
    padding-left: 4px;
    padding-right: 4px; } }

.tiles .tile + .tile {
  border-left: 2px solid #fff; }

.tiles .tile + .tile.is-active {
  border-left: none; }

.tile__header:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 150%;
  background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
  z-index: -1;
  pointer-events: none; }

.tile__header {
  position: relative;
  color: #fff;
  text-align: center;
  padding: 6.5vmin;
  z-index: 2;
  flex: none;
  width: 100%;
  flex-direction: column;
  display: flex;
  margin-top: auto; }
  @media only screen and (max-width: 1023px) {
    .tile__header {
      padding: 7.5vmin; } }
  @media only screen and (max-width: 320px) {
    .tile__header {
      padding: 12vmin; } }

.tile__header h2 {
  line-height: 1.3;
  font-weight: normal;
  margin: 0 0 1em;
  text-align: left;
  display: block;
  overflow: hidden;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; }

.tile__header h2 .fit {
  white-space: nowrap; }

.tile__header h2 .fit.focus {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1; }

.tile .is-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.tile__button {
  padding: .75em 1.5em;
  margin-top: auto;
  flex: none;
  background-color: rgba(0, 0, 0, 0.15);
  font-weight: 600;
  font-size: 21px; }
  @media screen and (max-width: 1200px) {
    .tile__button {
      font-size: calc(18px + 3 * ((100vw - 320px) / 880)); } }
  @media screen and (max-width: 320px) {
    .tile__button {
      font-size: 18px; } }

.tile:hover:not(.is-active) > .tile__bg {
  transition: transform 1500ms ease-in-out;
  transform: scale(1.05); }

.tile.is-active {
  z-index: 2;
  width: 100vw; }

.tile.is-active .tile__header {
  display: none; }

.tile.is-active .is-bg {
  transform: none; }

.tile.is-active:hover .tile__bg {
  transform: none; }

.tile.is-active > .tile__bg {
  filter: brightness(0.5); }

.tiles.tile-active .tile:not(.is-active) {
  width: 0 !important; }

.tiles.tile-active .tile + .tile {
  border: none; }

.tiles.tile-active .tile:not(.is-active) .tile__header,
.tiles.tile-active .tile:not(.is-active) .tile__bubble-holder {
  opacity: 0; }

@media only screen and (max-width: 767px) {
  .tile.is-active .tile-detail-header__bubble {
    display: none; } }

.question-overlay__inner {
  position: relative; }

.question {
  font-size: 21px;
  line-height: 1.7; }
  @media only screen and (max-width: 1200px) {
    .question {
      font-size: 17px;
      line-height: 1.6; } }
  @media only screen and (max-width: 1024px) {
    .question {
      font-size: 17px; } }
  @media only screen and (max-width: 480px) {
    .question {
      font-size: 17px; } }

.app-container.question-in-header .question-overlay br {
  display: none; }

.app-container.question-in-header .question-overlay .question__button {
  color: #3366ff;
  border-color: #3366ff;
  font-size: 24px; }
  @media screen and (max-width: 1200px) {
    .app-container.question-in-header .question-overlay .question__button {
      font-size: calc(18px + 6 * ((100vw - 320px) / 880)); } }
  @media screen and (max-width: 320px) {
    .app-container.question-in-header .question-overlay .question__button {
      font-size: 18px; } }

.app-container.question-fullscreen .question-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3366ff;
  z-index: 3;
  padding-top: 5em; }
  @media only screen and (max-width: 480px) {
    .app-container.question-fullscreen .question-overlay {
      text-align: center;
      padding-top: 2em; } }
  @media only screen and (max-width: 320px) {
    .app-container.question-fullscreen .question-overlay {
      padding-top: 3em; } }

.app-container.question-fullscreen .question-overlay .question {
  font-size: 60px;
  color: #fff;
  font-weight: 600; }
  @media screen and (max-width: 1200px) {
    .app-container.question-fullscreen .question-overlay .question {
      font-size: calc(21px + 39 * ((100vw - 320px) / 880)); } }
  @media screen and (max-width: 320px) {
    .app-container.question-fullscreen .question-overlay .question {
      font-size: 21px; } }

.app-container.question-fullscreen .question-overlay .question .question-layout {
  margin-bottom: 1em; }
  @media only screen and (max-width: 320px) {
    .app-container.question-fullscreen .question-overlay .question .question-layout {
      margin-bottom: .5em; } }

.app-container.question-fullscreen .question-overlay .question .question__button {
  font-size: 36px; }
  @media screen and (max-width: 1200px) {
    .app-container.question-fullscreen .question-overlay .question .question__button {
      font-size: calc(21px + 15 * ((100vw - 320px) / 880)); } }
  @media screen and (max-width: 320px) {
    .app-container.question-fullscreen .question-overlay .question .question__button {
      font-size: 21px; } }
  @media only screen and (max-width: 480px) {
    .app-container.question-fullscreen .question-overlay .question .question__button {
      padding: 0.7em 0.8em; } }

.app-container.question-fullscreen .question-overlay .question .question__button.is-disabled {
  color: #003399;
  border-color: #003399;
  pointer-events: none; }

.app-container.question-fullscreen .question-overlay .question .notification {
  font-size: 30px;
  margin-top: 2em; }
  @media screen and (max-width: 1200px) {
    .app-container.question-fullscreen .question-overlay .question .notification {
      font-size: calc(16px + 14 * ((100vw - 320px) / 880)); } }
  @media screen and (max-width: 320px) {
    .app-container.question-fullscreen .question-overlay .question .notification {
      font-size: 16px; } }
  @media only screen and (max-width: 480px) {
    .app-container.question-fullscreen .question-overlay .question .notification {
      margin: 1em .5em 0; } }

.question .dropdown .trigger1 {
  animation: colorChange .75s ease-in; }

.trigger2 {
  animation: colorChange 1.5s ease-in; }

.trigger3 {
  animation: colorChange 2.25s ease-in; }

@keyframes colorChange {
  0% {
    color: #fff; }
  100% {
    color: #003399; } }

.dropdown {
  display: inline-block;
  position: relative; }

.dropdown .icon {
  width: .65em;
  margin-left: .25em;
  margin-right: .35em; }

.dropdown.is-active .dropdown__list {
  visibility: visible;
  opacity: 1; }

.dropdown__list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%);
  left: 50%;
  z-index: 5;
  text-align: left;
  max-height: 35vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.dropdown__list li {
  background-color: #7fb6ff;
  color: #fff;
  margin-top: -1px;
  padding-left: .5em;
  padding-right: .5em;
  white-space: nowrap; }

.dropdown__list li:hover {
  color: #003399; }

.dropdown__list li.checked {
  color: #003399; }

.dropdown__trigger {
  color: #3366ff; }

.app-container.question-fullscreen .question-overlay .dropdown__trigger {
  color: #003399; }

.app-container.question-fullscreen .question-overlay .dropdown__list li {
  font-size: 70%; }
  @media only screen and (max-width: 1024px) {
    .app-container.question-fullscreen .question-overlay .dropdown__list li {
      font-size: 90%; } }
  @media only screen and (max-width: 767px) {
    .app-container.question-fullscreen .question-overlay .dropdown__list li {
      font-size: 100%; } }

.app-container.question-fullscreen .dropdown.is-active .dropdown__scroll {
  position: absolute;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(127, 182, 255, 0) 0%, #7fb6ff 100%);
  animation: scrollDown 4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  bottom: 0;
  opacity: 0; }

.app-container.question-fullscreen .header .dropdown__scroll .icon {
  fill: #003399;
  margin: 0; }

@keyframes scrollDown {
  0%, 20%, 40%, 60% {
    bottom: -10px;
    opacity: 1; }
  10%, 30%, 50% {
    bottom: 0;
    opacity: 1; }
  80%, 90%, 100% {
    bottom: -10px;
    opacity: 0; } }

.tile__detail-header {
  width: 100%;
  flex: none; }

.tile.is-active .tile__detail-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-bottom: 2px solid #fff;
  color: #fff;
  padding: 1.5em 6em 0; }
  @media only screen and (max-width: 1200px) {
    .tile.is-active .tile__detail-header {
      padding: 1.5em 0 0; } }

.tile.is-active .tile__detail-header .column.tile-detail-header__bubble {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 0;
  max-width: 100%; }

.tile.is-active .tile__detail-header .column.tile-detail-header__bubble {
  flex: none; }

.tile.is-active .tile__bubble-holder {
  padding: 0; }

.detail-overlay__nav {
  justify-content: space-between;
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0; }

.detail-overlay__nav a {
  display: block;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-bottom: .3em;
  opacity: .75; }

.detail-overlay__nav li {
  font-size: 1.5em; }
  @media only screen and (max-width: 480px) {
    .detail-overlay__nav li {
      font-size: 1.25em; } }

.detail-overlay__nav li.is-active a {
  border-bottom-color: #fff;
  opacity: 1;
  font-weight: 600; }

.detail-overlay__nav li + li {
  margin-top: 0; }

.tile__detail-header h1.city {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3em;
  margin: 0; }
  @media only screen and (max-width: 1024px) {
    .tile__detail-header h1.city {
      font-size: 2.5em; } }
  @media only screen and (max-width: 767px) {
    .tile__detail-header h1.city {
      font-size: 2em;
      padding-left: 1.5em; } }
  @media only screen and (max-width: 480px) {
    .tile__detail-header h1.city {
      font-size: 1.5em; } }

.tile__detail-header .close {
  position: absolute;
  left: -75px;
  top: 15px; }
  @media only screen and (max-width: 1024px) {
    .tile__detail-header .close {
      left: -47px;
      top: 10px; } }
  @media only screen and (max-width: 767px) {
    .tile__detail-header .close {
      left: 10px;
      top: 5px; } }

@media only screen and (max-width: 480px) {
  .tile__detail-header .close .icon {
    width: 1.3em;
    height: 1.3em; } }

.header {
  background: #fff;
  width: 100%;
  z-index: 20;
  padding: .75em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: none; }

.header .icon {
  fill: #3366ff;
  z-index: 20; }

.header .header-left .icon {
  width: 8em; }
  @media only screen and (max-width: 767px) {
    .header .header-left .icon {
      width: 6em; } }

.header .header-left {
  flex: none; }

.header .header-left a {
  display: block; }

.app-container.question-in-header .header .header-right {
  text-align: right; }
  @media only screen and (max-width: 480px) {
    .app-container.question-in-header .header .header-right {
      text-align: center;
      margin-top: .5em; } }

.app-container.question-fullscreen .header .icon {
  fill: #fff; }

.app-container.question-fullscreen .header {
  padding: 1.5em; }

.app-container.question-fullscreen .header .header-left a {
  display: flex; }

.tile__bubble-holder .information-bubble {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  padding: .5em 1.5em;
  flex: none;
  width: 100%;
  position: relative;
  color: #fff;
  text-align: center; }

.tile__bubble-holder .information-bubble h3 {
  margin: 0;
  font-size: 1em; }

.tile__bubble-holder .information-bubble p {
  margin: 0;
  font-size: 1.45em; }
  @media only screen and (max-width: 767px) {
    .tile__bubble-holder .information-bubble p {
      font-size: 1.2em; } }
  @media only screen and (max-width: 320px) {
    .tile__bubble-holder .information-bubble p {
      font-size: 1em; } }

.tile__detail-content {
  position: relative;
  color: #fff;
  z-index: 2;
  flex: none;
  width: 100%;
  padding-bottom: 5em;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }

.tile__detail-content > .container {
  overflow: hidden; }

.tile__detail-content h2 {
  font-weight: 600;
  font-size: 3.25em; }
  @media only screen and (max-width: 1024px) {
    .tile__detail-content h2 {
      font-size: 2.2em; } }
  @media only screen and (max-width: 767px) {
    .tile__detail-content h2 {
      font-size: 1.75em; } }
  @media only screen and (max-width: 480px) {
    .tile__detail-content h2 {
      margin-bottom: .5em; } }

.tile__detail-content h3 {
  font-weight: 600;
  font-size: 2.25em; }
  @media only screen and (max-width: 1024px) {
    .tile__detail-content h3 {
      font-size: 1.4em; } }
  @media only screen and (max-width: 480px) {
    .tile__detail-content h3 {
      margin-bottom: .5em; } }

.tile__detail-content p {
  font-size: 1.5em; }
  @media only screen and (max-width: 1024px) {
    .tile__detail-content p {
      font-size: 1.25em; } }

.tile__detail-content.intro {
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  padding: 0 6em 5em; }
  @media only screen and (max-width: 1200px) {
    .tile__detail-content.intro {
      padding: 0 0 5em; } }
  @media only screen and (max-width: 767px) {
    .tile__detail-content.intro {
      align-items: flex-start;
      padding: 2em 0; } }

.tile__detail-content.activity-items {
  padding: 2em 6em 2em; }
  @media only screen and (max-width: 1200px) {
    .tile__detail-content.activity-items {
      padding: 2em 0 2em; } }

.activity-list {
  list-style: none;
  padding: 0;
  pointer-events: none; }

.activity-list li {
  background-color: rgba(0, 0, 0, 0.5); }

.activity-list li .visual {
  display: flex;
  height: 15em;
  width: 26em; }
  @media only screen and (max-width: 1200px) {
    .activity-list li .visual {
      width: 20em; } }

.activity-list .media-text {
  padding: 1.5em 2em; }
  @media only screen and (max-width: 480px) {
    .activity-list .media-text {
      padding: 1.5em 1em; } }

@media only screen and (max-width: 480px) {
  .activity-list .media-text p {
    font-size: 1.1em; } }

.styleguide-section {
  margin: 3em 0 7em; }

.styleguide-section code {
  background-color: #efefef;
  display: inline-block;
  padding: .25em .5em;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: .85em;
  line-height: 1; }

.styleguide-logo {
  margin-left: auto;
  margin-right: auto;
  max-width: 200px; }

.styleguide-section h2 {
  font-size: 2em;
  margin: 0 0 .25em; }
  .styleguide-section h2 .styleguide-label {
    display: inline-block;
    background-color: #efefef;
    border-radius: 4px;
    padding: .2em .5em;
    border: 1px solid #ddd;
    font-size: .5em;
    vertical-align: middle; }

.styleguide-specs {
  width: 100%;
  margin-bottom: 2em;
  line-height: 1.6; }
  .styleguide-specs td,
  .styleguide-specs th {
    border: 1px solid #dcdcdc;
    border-width: 0 0 1px;
    padding: .75em 1em;
    vertical-align: top; }
  .styleguide-specs th {
    color: #333;
    text-align: left; }
  .styleguide-specs thead td,
  .styleguide-specs thead th {
    border-width: 0 0 2px;
    color: #333;
    font-weight: bold; }
  .styleguide-specs tfoot td,
  .styleguide-specs tfoot th {
    border-width: 2px 0 0;
    color: #333; }
  .styleguide-specs tbody tr:last-child td,
  .styleguide-specs tbody tr:last-child th {
    border-bottom-width: 0; }

.slideout-panel__close-trigger {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0);
  display: none;
  z-index: 2; }
  .slideout-open .slideout-panel__close-trigger {
    display: block; }

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
  background: #3366ff; }

.slideout-panel {
  position: relative;
  z-index: 1;
  background: #fff; }

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow-x: hidden; }

.slideout-open .slideout-menu {
  display: block; }

.filler {
  min-height: 40px;
  margin-bottom: 1.5em;
  text-align: center;
  color: #000;
  padding: 1.5em;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  font-weight: 500; }

.filler .filler {
  margin-bottom: 0; }
