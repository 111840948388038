.checkbox {
	@include visually-hidden;
}

.checkbox:not(.is-custom) {
	& + label {
		position: relative;
		display: inline-block;
		line-height: 1;
		width: auto;
		margin-bottom: rem($base-spacing);
		padding-right: rem($base-spacing);
		display: flex;
		cursor: pointer;
	}

	& + label:before {
		flex: none;
		content: "";
		width: 1em;
		height: 1em;
		border: 1px solid c(border);
		margin-right: .5em;
	}

	& + label .label {
		flex: 1;
	}

	&:checked + label > .icon {
		position: absolute;
		display: inline-block;
		top: -.1em;
		left: 0;
		width: rem(18);
		height: rem(18);
	}

	& + label > .icon {
		display: none;
	}
}

.checkbox.is-custom + label > .button.is-primary {
	position: relative;
	opacity: .3;
}

.checkbox.is-custom:checked + label > .button.is-primary {
	opacity: 1;
}

.checkbox.is-custom + label > .button.is-primary.has-icon-left {
	padding-left: 2.65em;
}

.checkbox.is-custom + label > .button.is-primary.has-icon-right {
	padding-right: 2.65em;
}
