.cover-image {
	@include background-positions('data-bg-position');
}

@keyframes fadeIn {
	from { opacity: 0 }
	to { opacity: 1 }
}

[class*="ratio"] {
	position: relative;
}

[class*="ratio-"] > img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

img.is-lazy,
img.is-loading,
.is-bg.is-lazy
.is-bg.is-loading {
	opacity: 0;
}

.is-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
}

img.is-loaded,
.is-bg.is-loaded {
	animation: fadeIn .5s both;
}


