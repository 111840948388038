//
// Responsive embed
// Credit: Suit CSS
// --------------------------------------------------

.is-responsive-embed {
	position: relative;

	.responsive-embed__item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
