.filler {
	min-height: 40px;
	margin-bottom: 1.5em;
	text-align: center;
	color: #000;
	padding: 1.5em;
	background-color: rgba(#000, .1);
	border-radius: $base-spacing / 2;
	text-align: center;
	font-weight: 500;
}

.filler .filler {
	margin-bottom: 0;
}