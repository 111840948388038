//
//	Automatic theme color, bg & border-color generator
// --------------------------------------------------

	@if($output-color-classes == true) {
		@each $color, $value in $colors {
			html .is-color-#{$color} {
				color: $value;
			}

			html .is-background-#{$color} {
				background-color: $value;
			}

			html .is-border-#{$color} {
				border-color: $value;
			}
		}
	}

.is-clearfix {
	@include clearfix();
	display: block;
}

html .is-hidden {
	display: none;
}

.is-full-link {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: z-index(full-link);
	background: rgba(#000, 0); // IE fix
	display: block;
}

@mixin circle {
	border-radius: 50%;
}

@mixin radius {
	border-radius: $border-radius;
}

@mixin block-center {
	@include spacing('x', auto, 'margin');
}

@if($output-show-hide-classes == true) {

	html .is-visible-block,
	html .is-visible {
		display: block;
	}

	html .show-inline-block,
	html .inline-block {
		@include inline-block;
	}

	@each $breakpoint-name, $breakpoint-value in $breakpoints {
		html .is-hidden-#{$breakpoint-name} {
			@include breakpoint($breakpoint-name) {
				display: none;
			}
		}

		html .is-visible-#{$breakpoint-name}-block,
		html .is-visible-#{$breakpoint-name} {
			display: none;

			@include breakpoint($breakpoint-name ) {
				display: block;
			}
		}

		html .is-visible-#{$breakpoint-name}-inline-block {
			display: none;

			@include breakpoint($breakpoint-name) {
				@include inline-block;
			}
		}
	}
}

@if($output-text-alignment-classes == true) {

	$alignments: ('left', 'center', 'right');

	@each $alignment in $alignments {
		.is-text-#{$alignment} {
			text-align: #{$alignment};
		}
	}

	@each $breakpoint-name, $breakpoint-value in $breakpoints {
		@each $alignment in $alignments {
			.is-text-#{$alignment}-#{$breakpoint-name} {
				@include breakpoint($breakpoint-name) {
					text-align: #{$alignment};
				}
			}
		}
	}
}

@if($output-float-classes == true) {

	.is-pulled-left {
		margin-right: auto;
	}

	.is-pulled-right {
		margin-left: auto;
	}

	.is-push-right {
		float: right;
	}

	.is-push-left {
		float: left;
	}
}

.is-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}

.is-visually-hidden {
	@include visually-hidden;
}

html .has-vertical-rhythm {
	@include vertical-rhythm(1.5em);
}

html.ie8 .is-visually-hidden {
	display: none !important;
	*display: none !important;
}